import ko from 'knockout';

export const delayVisible = {

  update: function (element, valueAccessor, allBindings) {

    var value = ko.utils.unwrapObservable(valueAccessor());
    var delay = allBindings.get('delay') || 500; // 500 ms is default delay unless otherwise specified

    if (value === true) {

      setTimeout(function () {
        value = ko.utils.unwrapObservable(valueAccessor());
        setVisiblity(element, value);
      }, delay);

    } else {
      setVisiblity(element, false);
    }
  }
};

function setVisiblity(element, value) {

  var isCurrentlyVisible = (element.style.display !== 'none');

  if (value && !isCurrentlyVisible) {
    element.style.display = '';
  } else if (!value && isCurrentlyVisible) {
    element.style.display = 'none';
  }
}
