import moment from 'moment';

// TODO: This module is growing organically but only contains date method currently. Look to rename in future?

function BindingHelper() {

  var self = this;

  /**
   * @param {Date|string} value A native JavaScript date or an ISO 8601 date string
   * @return {*}
   */
  self.getMomentDate = function (value) {

    // If value is a js Date object create a moment object without specific formats as it will fail the "strict" parse
    if (self.isJsDate(value)) {
      value = moment(value);
    }

    // Try to parse the text using the given format
    // The last parameter is "restrict" so it will not coerce any string into a date
    var date = moment(value, moment.ISO_8601, true);

    return date;
  };

  self.isJsDate = function (value) {
    return Object.prototype.toString.call(value) === '[object Date]';
  };
}

export default new BindingHelper();

