import ko from 'knockout';
import resourceHelper from '../Utils/resourceHelper';

/**
   * Resource aria-label binding to display translated aria-label text based on
   * content in the resource files.
   * @example
   * <button data-bind="resAriaLabel: 'Key'"></h1>
   * <input data-bind="resAriaLabel: { key: 'Key', params: { name: 'Ian Craig' } }">
   */

export const resAriaLabel = {
  init: function (element, valueAccessor) {

    var value = ko.utils.unwrapObservable(valueAccessor());

    // Allow both formats of:
    // - resAriaLabel: 'ToggleSelection'
    // - resAriaLabel: { key: 'ToggleSelection', params: { 'requestId': 'CIT0001' } }
    var valueIsString = typeof value === 'string' || value instanceof String;
    var key = valueIsString ? value : value.key;
    var params = valueIsString ? {} : value.params;

    var text = resourceHelper.getString(key, params);
    ko.applyBindingsToNode(element, { attr: { 'aria-label': text } });
  }
};
