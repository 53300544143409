import * as ko from 'knockout';
import { BindingHandler, Observable } from 'knockout';
import logger from '@/Utils/logger';

/**
 * Binding to apply to a input of type upload which listens to the input change event and calls the
 * provided function when one or more files have been selected.
 */
export const cctvFolderInput = {
  init: (
    element: HTMLElement,
    valueAccessor: () => Observable): void => {

    const addFileUploadsCallback = valueAccessor();
    const tagName = element.tagName;
    if (tagName !== 'INPUT') {
      logger.error('UnhandledError', 'The cctv folder input binding can only be applied to a input tag.');
    }

    if (typeof addFileUploadsCallback !== 'function') {
      logger.error('UnhandledError', 'The value provided is not a function / callback.');
    }

    const eventHandler = event => {

      const input = event.target;

      // Allow for browsers that handle the value reset / empty string as a change
      if (input.value === '') {
        return;
      }

      const fileList = input.files;
      Array.from(fileList)
        .forEach(file => {
          Object.defineProperty(file, 'isCctv', {
            value: true
          });
        });
      addFileUploadsCallback(fileList);

      // Set to empty string to reset and allow same cctv folder to be selected again
      input.value = '';
    };

    element.addEventListener('change', eventHandler);

    ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
      element.removeEventListener('change', eventHandler);
    });
  }
} as BindingHandler;
