import $ from 'jquery';
import logger from '@/Utils/logger';
import dropZoneUploadProvider from '@/Bindings/helpers/dropZoneUploadProvider';

/**
 * Binding to make an element act as a drop zone for individual file and folder uploads. Child elements within the element will
 * trigger the "dragleave" so a counter is used to determine when enter and leave events occurs just on
 * the element and not child elements.
 */
export const fileDropZone = {
  init: function (element, valueAccessor) {

    var dropZoneElement = $(element);
    var addFileUploadsCallback = valueAccessor();
    var counter = 0;

    dropZoneElement.on('dragover', function (e) {
      e.stopPropagation();
      e.preventDefault();
    });

    dropZoneElement.on('dragenter', function (e) {
      e.stopPropagation();
      e.preventDefault();

      counter++;

      dropZoneElement.css('background-color', '#FDFFB7');
      dropZoneElement.css('border-style', 'dashed');
    });

    dropZoneElement.on('dragleave', function (e) {
      e.stopPropagation();
      e.preventDefault();

      counter--;

      if (counter === 0) {
        dropZoneElement.css('background-color', '');
        dropZoneElement.css('border-style', 'solid');
      }
    });

    dropZoneElement.on('drop', function (e) {
      e.stopPropagation();
      e.preventDefault();

      counter = 0;
      dropZoneElement.css('background-color', '');
      dropZoneElement.css('border-style', 'solid');

      dropZoneUploadProvider.getFilesFromEventItems(e.originalEvent.dataTransfer.items, false)
        .then(function (files) {
          if (files && files.length > 0) {
            Array.from(files)
              .forEach(file => {
                Object.defineProperty(file, 'isFile', {
                  value: true
                });
              });
            addFileUploadsCallback(files);
          } else if (files) {
            logger.warning('EmptyFolderUploadWarningMessage');
          }
        }).catch(function (error) {
          logger.error(
            'UnhandledError',
            'An unexpected error occurred while attempting add file or folder uploads.', error);
        });
    });
  }
};
