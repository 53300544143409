import ko from 'knockout';
import mapObservableHelper from '../helpers/mapObservableHelper';
import logger from '../../Utils/logger';
import contextData from '../../contextData';

export const map = {

  init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    // Ignore that viewModel argument is not in use

    if (!element.id) {
      logger.error('UnhandledError', 'The map binding must be used on an element with an Id specified.');
    }

    var defaultCenterCoordinate = { latitude: 51.5, longitude: -0.1 }; // London
    var centerObservable = mapObservableHelper.getAccessorObservable(valueAccessor, defaultCenterCoordinate);
    var centerLonLat = [centerObservable().longitude, centerObservable().latitude];

    var defaultZoom = 2;
    var zoomObservable = mapObservableHelper.getBindingObservable('zoom', allBindings, defaultZoom);
    var zoom = zoomObservable();

    var minZoomLevel = contextData.portalSettings.mapViewMinZoomLevel;
    var maxZoomLevel = contextData.portalSettings.mapViewMaxZoomLevel;

    import(/* webpackChunkName: "open-layers" */ './openLayersDynamicModule') // Dynamically import large OpenLayers dependencies
      .then(olModule => {

        const Map = olModule.Map;
        const TileLayer = olModule.TileLayer;
        const View = olModule.View;
        const OSM = olModule.OSM;
        const toLonLat = olModule.toLonLat;
        const fromLonLat = olModule.fromLonLat;

        var map = new Map({
          target: element.id,
          layers: [
            new TileLayer({
              source: new OSM()
            })
          ],
          view: new View({
            center: fromLonLat(centerLonLat),
            zoom: zoom,
            minZoom: minZoomLevel,
            maxZoom: maxZoomLevel
          })
        });

        var centerSubscription = centerObservable.subscribe(function (newValue) {
          var lonLat = [newValue.longitude, newValue.latitude];
          map.getView().setCenter(fromLonLat(lonLat));
        });

        var zoomSubscription = zoomObservable.subscribe(function (newValue) {
          map.getView().setZoom(newValue);
        });

        var moveendEventKey = map.on('moveend', function () {

          var centerLonLat = toLonLat(map.getView().getCenter());
          var zoom = map.getView().getZoom();

          centerObservable({ latitude: centerLonLat[1], longitude: centerLonLat[0] });
          zoomObservable(zoom);
        });

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {

          centerSubscription.dispose();
          zoomSubscription.dispose();

          // Remove map move event listener
          map.un('moveend', moveendEventKey);

          map.setTarget(null);
          map = null;
        });

        // Create a modified binding context including the map and use to bind to descendant elements / child map bindings
        var childBindingContext = bindingContext.extend({ map: map });
        ko.applyBindingsToDescendants(childBindingContext, element);
      });

    // Tell KO *not* to bind the descendants itself, otherwise they will be bound twice
    return { controlsDescendantBindings: true };
  }
};
