import $ from 'jquery';
import ko from 'knockout';

export const enterKey = {

  init: function (element, valueAccessor, allBindingsAccessor, viewModel) {
    // Ignore valueAccessor not used

    var allBindings = allBindingsAccessor();

    $(element).keypress(function (event) {

      var keyCode = (event.which ? event.which : event.keyCode);

      if (keyCode === 13) {
        allBindings.enterKey();
        return false;
      }

      return true;
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      $(element).unbind('keypress');
    });
  }
};
