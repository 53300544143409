import $ from 'jquery';
import ko from 'knockout';
import bindingHelper from './helpers/bindingHelper';

export const requiredBySummary = {

  update: function (element, valueAccessor, allBindingsAccessor, viewModel, context) {

    var value = ko.utils.unwrapObservable(valueAccessor());

    // If the accessor is a falsey value (including null) do nothing
    if (!value) {
      return;
    }

    var date = bindingHelper.getMomentDate(value).toDate();

    var dueInDays = getDaysBetween(new Date(), date);
    var bindingValue = { key: 'DueToday', params: {} }; // Start with a restext binding for today
    var bindingValueAccessor = function () {
      return bindingValue;
    };

    if (dueInDays === 1) {
      bindingValue.key = 'DueTomorrow';
    } else if (dueInDays === -1) {

      bindingValue.key = 'DueYesterday';

      $(element).addClass('text-danger');
    } else if (dueInDays > 0) {

      bindingValue.key = 'DueInXDays';
      bindingValue.params.days = dueInDays;
    } else if (dueInDays < 0) {

      bindingValue.key = 'OverdueByXDays';

      if (dueInDays < -100) { // If more than 100 days ago
        bindingValue.params.days = '100+';
      } else {
        bindingValue.params.days = Math.abs(dueInDays);
      }

      $(element).addClass('text-danger');
    }

    ko.bindingHandlers.restext.update(element, bindingValueAccessor, allBindingsAccessor, viewModel, context);
  }
};

function getDaysBetween(date1, date2) {

  // Ensure dates are treated as UTC when being compared
  var utcDate1 = Date.UTC(date1.getUTCFullYear(), date1.getUTCMonth(), date1.getUTCDate());
  var utcDate2 = Date.UTC(date2.getUTCFullYear(), date2.getUTCMonth(), date2.getUTCDate());
  var milliseconds = utcDate2 - utcDate1;

  // Floor should be unnecessary as stripped time aspect but will play it safe!
  return Math.floor(milliseconds / 1000 / 60 / 60 / 24);
}
