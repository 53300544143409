import $ from 'jquery';
import ko from 'knockout';

export const showOverlay = {

  init: function (element: HTMLElement, valueAccessor: any, allBindings: any): void {

    const observable = valueAccessor();
    const afterShowCallback = allBindings.get('afterShowCallback');

    $(element).on('shown.bs.modal', function () {
      observable(true);

      if (typeof afterShowCallback === 'function') {
        afterShowCallback();
      }

    });

    // avoid hiding as overlay is permanent
    $(element).on('hide.bs.modal', function () {
      return false;
    });

  },

  update: function (element: HTMLElement, valueAccessor: any, allBindings: any): void {
    const value = ko.utils.unwrapObservable(valueAccessor());

    if (value) {
      $(element).modal('show');
      return;
    }
  }
};
