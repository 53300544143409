export default class DropZoneUploadProvider {
  static async getFilesFromEventItems(items: DataTransferItemList, isCctv: boolean): Promise<any[]> {
    return new Promise((resolve, reject) => {

      const promises: Promise<any>[] = [];

      Array.from(items)
        .forEach((item: DataTransferItem) => {
          let entry = null;

          // try to read item as entry. `webkitGetAsEntry` method can we deprecated and removed soon
          if (item.webkitGetAsEntry) {
            entry = item.webkitGetAsEntry();
          } else if (item.getAsEntry) {
            entry = item.getAsEntry();
          }

          if (isValidEntry(entry, isCctv)) {
            promises.push(scanFiles(entry, isCctv));
          }
        });

      Promise.all(promises)
        .then((result: any) => {
          const files = flattenDeep(result);
          resolve(files);
        })
        .catch((error: any) => {
          reject(error);
        });
    });

    function isValidEntry(entry: any, isCctv: boolean) {
      if (!entry) {
        return false;
      }
      // In order to add support of files drag'n'drop `entry.isDirectory` verification should be omitted.
      if (isCctv) {
        return entry.isDirectory;
      }
      return true;
    }

    function scanFiles(item: any, isCctv: boolean) {
      return new Promise((resolve, reject) => {
        if (item.isFile) {
          item.file((file: any) => {
            if (isCctv) {
              // webkitRelativePath was missing
              const webkitRelativePath = item.fullPath.substring(1, item.fullPath.length);
              Object.defineProperty(file, 'webkitRelativePath', {
                value: webkitRelativePath
              });
            }
            resolve(file);
          });
        } else {
          const reader = item.createReader();
          readEntries(reader)
            .then(entries => {

              const promises: Promise<any>[] = [];

              Array.from(entries)
                .forEach((entry: any) => {
                  promises.push(scanFiles(entry, isCctv));
                });

              Promise.all(promises)
                .then((result: any) => {
                  const files = result;
                  resolve(files);
                })
                .catch((error: any) => {
                  reject(error);
                });
            })
            .catch(errorCallback);
        }


        function errorCallback(error: any) {
          reject(error);
        }

        function readEntries(directoryReader: FileSystemDirectoryReader) {
          return new Promise((resolve, reject) => {
            directoryReader.readEntries(
              function (entries) {
                if (entries.length > 0) {
                  readEntries(directoryReader)
                    .then((result: any) => {
                      resolve(entries.concat(result));
                    })
                    .catch((err: any) => {
                      reject(err);
                    });
                } else {
                  resolve(entries);
                }
              },
              (error: any) => {
                reject(error);
              }
            );
          });
        }
      });
    }

    function flattenDeep(arr: any[]): any[] {
      return arr.reduce((flat, toFlatten) => {
        return flat.concat(Array.isArray(toFlatten) ? flattenDeep(toFlatten) : toFlatten);
      }, []);
    }
  }
}
