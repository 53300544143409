import * as ko from 'knockout';
import { BindingHandler } from 'knockout';

/**
 * Binding to make an element sticky using native `position: sticky` CSS. This will make the element
 * sticky on all modern browsers. Very old browsers like IE11 won't make the element sticky.
 *
 * An intersection observer is used when supported to add a shadow class to the element once it's sticky.
 * @type {BindingHandler}
 */
export const stickyElementBinding = {

  init: (element: HTMLElement): void => {
    const supportsIntersectionObserver = 'IntersectionObserver' in window;

    element.style.position = 'sticky'; // Set this element to use position sticky for modern browsers
    element.style.zIndex = '11'; // Raise the stack level so the sticky element is above other elements

    // If the browser supports IntersectionObserver then use a -1px top position to force an
    // intersection with the top of the browser when the header is sticky.
    element.style.top = supportsIntersectionObserver ? '-1px' : '0';

    if (supportsIntersectionObserver) {
      const observer = new IntersectionObserver(
        // If less than the whole element is displayed (which happens when the element is sticky as the first
        // pixel is hidden due to 'top: -1px') then toggle the sticky class. IntersectionObserver used for
        // performance reasons.
        ([e]) => e.target.classList.toggle('is-stuck', e.intersectionRatio < 1),
        { threshold: [1] }
      );

      observer.observe(element);

      ko.utils.domNodeDisposal.addDisposeCallback(element, () => {
        observer.unobserve(element);
      });
    }
  }
} as BindingHandler;
