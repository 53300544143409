import ko from 'knockout';
import mapObservableHelper from '../helpers/mapObservableHelper';
import mapStylingHelper from '../helpers/mapStylingHelper';
import logger from '../../Utils/logger';

export const mapDraggableMarker = {
  init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {
    // Ignore that element, allBindings and viewModel arguments are not in use

    if (typeof bindingContext.map === 'undefined') {
      logger.error(
        'UnhandledError',
        'The draggable marker binding must only be used inside the scope of a map binding.');
    }

    var map = bindingContext.map;

    var coordinateObservable = mapObservableHelper.getAccessorObservable(valueAccessor);
    var markerLonLat = [coordinateObservable().longitude, coordinateObservable().latitude];

    import(/* webpackChunkName: "open-layers" */ './openLayersDynamicModule') // Dynamically import large OpenLayers dependencies
      .then(olModule => {

        const Collection = olModule.Collection;
        const Feature = olModule.Feature;
        const Point = olModule.Point;
        const VectorLayer = olModule.VectorLayer;
        const VectorSource = olModule.VectorSource;
        const toLonLat = olModule.toLonLat;
        const fromLonLat = olModule.fromLonLat;
        const Translate = olModule.Translate;

        var vectorLayer = new VectorLayer({
          source: new VectorSource()
        });

        var lastMapLngLat = [0, 0];

        map.addLayer(vectorLayer);

        // TODO: Check the dataType parameter exists before attempting to use
        var style = mapStylingHelper.createDraggableMarkerStyle(allBindings().dataType);

        var markerPoint = new Point(fromLonLat(markerLonLat));
        var markerFeature = new Feature(markerPoint);

        markerFeature.setStyle(style);

        vectorLayer.getSource().addFeature(markerFeature);

        var collection = new Collection([markerFeature]);
        var drag = new Translate({
          features: collection
        });
        map.addInteraction(drag);

        var coordinateSubscription = coordinateObservable.subscribe(function (newValue) {

          var observableLonLat = fromLonLat([newValue.longitude, newValue.latitude]);

          var mapLonLat = markerFeature.getGeometry().getCoordinates();

          var deltaX = observableLonLat[0] - mapLonLat[0];
          var deltaY = observableLonLat[1] - mapLonLat[1];

          markerFeature.getGeometry().translate(deltaX, deltaY);
        });

        markerFeature.on('change', onMarkerChange, markerFeature);

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {

          coordinateSubscription.dispose();

          // Remove map feature change event listener
          markerFeature.un('change', onMarkerChange);
        });

        function onMarkerChange(event) {

          var currentLonLat = toLonLat(event.target.getGeometry().getCoordinates());

          // If the marker hasn't moved then don't set the observable otherwise the observable subscription will
          // execute which will update the map and then fire this event again causing an infinite loop.
          if (currentLonLat[0] === lastMapLngLat[0] && currentLonLat[1] === lastMapLngLat[1]) {
            return;
          }

          lastMapLngLat = currentLonLat;

          coordinateObservable({ latitude: currentLonLat[1], longitude: currentLonLat[0] });
        }

      });
  }
};

