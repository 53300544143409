import $ from 'jquery';
import ko from 'knockout';
import mapStylingHelper from '../helpers/mapStylingHelper';
import logger from '../../Utils/logger';

/**
 * Binding to map a tooltip / popup for items on the map. This binding acts as a parent binding for other
 * child bindings to use.
 */
export const mapPopup = {
  init: function (element, valueAccessor, allBindings, viewModel, bindingContext) {

    if (typeof bindingContext.map === 'undefined') {
      logger.error('UnhandledError', 'The map popup binding must only be used inside the scope of a map binding.');
    }

    var map = bindingContext.map;
    var containerElement = document.getElementById('popup-container');
    var contentElement = document.getElementById('popup-content');
    var closeElement = document.getElementById('popup-close');

    if (!containerElement || !contentElement || !closeElement) {
      logger.error('UnhandledError', 'The required "popup-*" elements for the popup binding are missing.');
    }

    import(/* webpackChunkName: "open-layers" */ './openLayersDynamicModule') // Dynamically import large OpenLayers dependencies
      .then(olModule => {

        const Overlay = olModule.Overlay;

        var overlay = new Overlay({
          element: containerElement
        });

        map.addOverlay(overlay);

        $(closeElement).on('click', function () {
          overlay.setPosition(undefined);
          contentElement.innerHTML = '';
        });

        // Create an object which will be used to interact with the popup and add to the binding context for
        // child bindings to use.
        var mapPopup = {
          showPopup: function (feature, item, dataType) {

            var coordinate = feature.getGeometry().getCoordinates();
            contentElement.innerHTML = mapStylingHelper.getPopupContent(item, dataType);
            overlay.setPosition(coordinate);
          }
        };

        ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
          $(closeElement).off('click');
        });

        // Create a modified binding context including the map popup and use to bind to descendant elements / child map bindings
        var childBindingContext = bindingContext.extend({ mapPopup: mapPopup });
        ko.applyBindingsToDescendants(childBindingContext, element);
      });

    // Tell KO *not* to bind the descendants itself, otherwise they will be bound twice
    return { controlsDescendantBindings: true };
  }
};
