import ko from 'knockout';

/**
   * ListBox KeyPress event binding provide functionality to navigate by keyboard
   * on rolled as listbox block.
   * @example
   * <div role="listbox" data-bind="listboxKeyPress: { displayedItems: filteredUsers, elementIdPropertyName: 'personaId', selectedIndex: index, selectedId: selectedItemId }">
   */

export const listboxKeyPress = {
  init: function (element, valueAccessor) {

    const objects = ko.utils.unwrapObservable(valueAccessor());
    displayedItems = objects.displayedItems;
    elementIdPropertyName = objects.elementIdPropertyName;
    selectedIndex = objects.selectedIndex;
    selectedId = objects.selectedId;

    element.addEventListener('keypress', keyPressHandler);
    element.addEventListener('keydown', keyPressHandler);

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      element.removeEventListener('keypress', keyPressHandler);
      element.removeEventListener('keydown', keyPressHandler);
    });
  }
};

const arrowDown = 'ArrowDown';
const arrowUp = 'ArrowUp';
let displayedItems;
let elementIdPropertyName;
let selectedIndex;
let selectedId;

function keyPressHandler(event) {
  if (event.key === arrowUp || event.key === arrowDown) {

    selectedIndex = calculateIndex(event.key);
    const item = displayedItems()[selectedIndex];
    if (item && item[elementIdPropertyName] !== selectedId()) {

      // Event is cancelled when handled so handler is only run once
      event.preventDefault();
      selectedId(item[elementIdPropertyName]);
      const targetElement = document.getElementById(item[elementIdPropertyName]);
      targetElement.scrollIntoView();
    }
  }

  // should return true to not prevent all keys
  return true;
}

function calculateIndex(eventKey) {

  let result;

  if (selectedId() === null) {
    result = -1;
  } else {
    result = displayedItems().findIndex(x => x[elementIdPropertyName] === selectedId());
  }

  result = eventKey === arrowUp ? --result : ++result;

  if (result < 0) {
    return 0;
  } else if (result > displayedItems().length - 1) {
    return displayedItems().length - 1;
  }

  return result;
}
