import * as ko from 'knockout';
import { AllBindings, BindingHandler, Observable } from 'knockout';
import resourceHelper from '../Utils/resourceHelper';

const resTitleCallback = (element: HTMLElement, valueAccessor: () => Observable, allBindings: AllBindings, viewModel: any): void => {
  const value = ko.utils.unwrapObservable(valueAccessor());
  const valueIsString = typeof value === 'string' || value instanceof String;
  const key = valueIsString ? value : value.key;
  const params = valueIsString ? {} : value.params;
  const text = resourceHelper.getString(key, params);

  ko.applyBindingsToNode(element, { attr: { 'title': text } }, viewModel);
};

const bindingHandler: BindingHandler = {
  init: resTitleCallback,
  update: resTitleCallback
};

export const resTitle = bindingHandler;
