import $ from 'jquery';
import ko from 'knockout';

export const affix = {

  init: function (element, valueAccessor) {

    var options = ko.utils.unwrapObservable(valueAccessor()) || {};
    var topOffsetOption = options.topOffset || 0;
    var elementPosition = $(element).offset();

    $(element).affix({
      offset: { top: elementPosition.top - topOffsetOption }
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      $(element)
        .removeData('bs.affix')
        .removeClass('affix affix-top affix-bottom');
    });
  }
};

