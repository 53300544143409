import { Circle, Fill, Icon, Stroke, Style, Text } from 'ol/style.js';
import bindingHelper from '../helpers/bindingHelper';
import resourceHelper from '../../Utils/resourceHelper';

/**
 * Helper to handle styling of elements on the map
 */
function MapStylingHelper() {

  // ReSharper disable FunctionsUsedBeforeDeclared
  var self = this;

  /**
   * Create a draggable marker for the given data type
   * @param {string} dataType
   * @return {*}
   */
  self.createDraggableMarkerStyle = function (dataType) {

    if (dataType === 'business') {
      return createImageMarkerStyle('draggableBusinessMarker.png');
    }

    if (dataType === 'camera') {
      return createImageMarkerStyle('draggableCameraMarker.png');
    }

    if (dataType === 'group') {
      return createImageMarkerStyle('draggableGroupMarker.png');
    }

    throw new Error(`The dataType of ${dataType} is not supported.`);
  };

  /**
   * Create a point marker containing the given text
   * @param {string} text
   * @return {*}
   */
  self.createPointMarkerStyle = function (text) {

    return new Style({
      image: new Circle({
        radius: 10,
        stroke: new Stroke({
          color: '#575554',
          width: 2
        }),
        fill: new Fill({
          color: '#ffffff'
        })
      }),
      text: new Text({
        textAlign: 'start',
        textBaseline: 'middle',
        offsetX: 15,
        offsetY: 0,
        font: '16px RobotoRegular, Helvetica, Arial, sans-serif',
        fill: new Fill({ color: '#222' }),
        stroke: new Stroke({
          color: '#fff',
          width: 2
        }),
        text: text
      })
    });
  };

  self.getPopupContent = function (item, dataType) {

    if (dataType === 'business') {
      return createBusinessPopupContent(item);
    }

    return createCameraPopupContent(item);
  };

  function createCameraPopupContent(camera) {
    var date = bindingHelper.getMomentDate(camera.addedDateTime);

    return `<a href="#cameras/${camera.cameraId}"><strong>${camera.cameraName}</strong></a><br />` +
      `<div class="heading">${resourceHelper.getString('Added')}</div>` +
      date.format('llll');
  }

  function createBusinessPopupContent(business) {
    return `<strong>${business.name}</strong><br />` + business.address;
  }

  function createImageMarkerStyle(imageFileName) {

    return new Style({
      image: new Icon({
        anchor: [0.5, 1.0],
        anchorXUnits: 'fraction',
        anchorYUnits: 'fraction',
        opacity: 0.75,
        src: 'Content/images/' + imageFileName
      })
    });
  }
}

export default new MapStylingHelper();
