import $ from 'jquery';
import ko from 'knockout';

/**
 * Binding to prevent a click event from being propagated / bubbled to other elements
 */
export const preventClickEvent = {

  init: function (element) {

    $(element).on('click', function (e) {
      e.stopPropagation();
    });

    ko.utils.domNodeDisposal.addDisposeCallback(element, function () {
      $(element).off('click');
    });
  }
};
