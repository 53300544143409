import $ from 'jquery';
import 'blueimp-file-upload';

import uploadManager from '../Utils/uploadManager';
import config from '../config';
import contextData from '../contextData';

/**
 * Binding to create an instance of the jQuery File Upload functionality against a DOM element to
 * support uploading of files in chunks. This binding uses the "fileupload" module to observe the file
 * uploads array for changes as well as to update the status and progess of uploads.
 */
export const uploadEngine = {
  init: function (element) {

    var uploadUrl = contextData.portalSettings.fileUploadServiceRootUrl + 'api/upload';
    const maxConcurrentUploads = contextData.portalSettings.maximumConcurrentUploads;

    // View all available jQuery File Upload library options here:
    // https://github.com/blueimp/jQuery-File-Upload/wiki/Options

    $(element).fileupload({
      url: uploadUrl,
      maxChunkSize: config.uploadMaxChunkSize,
      limitConcurrentUploads: maxConcurrentUploads,
      dataType: 'json',
      dropZone: null, // Use our own element as the drop zone

      beforeSend: function (xhr, data) {
        var file = getSingleFile(data);
        var uploadToken = uploadManager.getUploadToken(file);
        xhr.setRequestHeader('Authorization', 'UploadToken ' + uploadToken);
      },
      send: function (e, data) {
        var file = getSingleFile(data);
        uploadManager.setUploadStarted(file);
      },
      progressall: function (e, data) {
        var progress = parseInt(data.loaded / data.total * 100, 10);
        uploadManager.setAllUploadsProgress(progress);
      },
      progress: function (e, data) {
        var progress = parseInt(data.loaded / data.total * 100, 10);
        var file = getSingleFile(data);
        uploadManager.updateUploadProgress(file, progress, data.loaded);
      },
      done: function (e, data) {
        var file = getSingleFile(data);
        uploadManager.setUploadComplete(file);
      },
      fail: function (e, data) {
        var file = getSingleFile(data);
        uploadManager.setUploadFailed(file);
      }
    });

    uploadManager.uploads.subscribe(function (changes) {

      $.each(changes, function (index, change) {

        var upload = change.value;

        if (change.status === 'added') {
          var ajaxRequest = $(element).fileupload('send', { files: [upload.nativeBrowserFile] });
          upload.setAjaxRequest(ajaxRequest);
        }
      });

    }, null, 'arrayChange');

    // Prevent the browser default drag and drop behaviour so if a file is dragged onto the web page but
    // outside of a drop zone then the file browser doesn't load the local file.
    $(document).on('dragover', function (e) {
      e.preventDefault();
    });

    $(document).on('drop', function (e) {
      e.preventDefault();
    });

    function getSingleFile(data) {

      if (data.files.length !== 1) {
        throw new Error('The data object is expected to hold exactly one file');
      }

      return data.files[0];
    }
  }
};
