import ko from 'knockout';

import { affix } from './Bindings/affixBinding';
import { enterTriggersElementClick } from './Bindings/enterTriggersElementClickBinding';
import { delayVisible } from './Bindings/delayVisibleBinding';
import { disableElement } from './Bindings/disableElementBinding';
import { editableSelect } from './Bindings/editableSelectBinding';
import { enterKey } from './Bindings/enterKeyBinding';
import { fileDropZone } from './Bindings/fileDropZoneBinding';
import { fileInput } from './Bindings/fileInputBinding';
import { cctvFolderInput } from './Bindings/cctvFolderInputBinding';
import { fileSize } from './Bindings/fileSizeBinding';
import { triggerClick } from './Bindings/triggerClick';
import { listboxKeyPress } from './Bindings/listboxKeyPressBinding';
import { localDate } from './Bindings/localDateBinding';
import { preventClickEvent } from './Bindings/preventClickEventBinding';

import { requiredBySummary } from './Bindings/requiredBySummaryBinding';
import { resplaceholder } from './Bindings/resplaceholderBinding';
import { restext } from './Bindings/restextBinding';
import { resTitle } from './Bindings/resTitleBinding';
import { resAriaLabel } from './Bindings/resAriaLabel';
import { ariaInvalid } from './Bindings/ariaInvalid';
import { showModal } from './Bindings/showModalBinding';
import { showOverlay } from './Bindings/showOverlayBinding';
import { stickyElementBinding } from './Bindings/stickyElementBinding';
import { textareaAutoResize } from './Bindings/textareaAutoResizeBinding';
import { uploadDropZone } from './Bindings/uploadDropZoneBinding';
import { uploadEngine } from './Bindings/uploadEngineBinding';

import { restooltip, tooltip } from './Bindings/tooltipBindings';

import { map } from './Bindings/map/mapBinding';
import { mapDraggableMarker } from './Bindings/map/mapDraggableMarkerBinding';
import { mapMarkers } from './Bindings/map/mapMarkersBinding';
import { mapPopup } from './Bindings/map/mapPopupBinding';

import { dynamicForm } from './Bindings/dynamicFormBinding';

class BindingRegistration {

  static registerAllBindings() {
    BindingRegistration.registerStandardBindings();
    BindingRegistration.registerMapsBindings();
  }

  static registerStandardBindings() {

    ko.bindingHandlers.affix = affix;
    ko.bindingHandlers.enterTriggersElementClick = enterTriggersElementClick;
    ko.bindingHandlers.delayVisible = delayVisible;
    ko.bindingHandlers.disableElement = disableElement;
    ko.bindingHandlers.editableSelect = editableSelect;
    ko.bindingHandlers.enterKey = enterKey;
    ko.bindingHandlers.fileDropZone = fileDropZone;
    ko.bindingHandlers.fileInput = fileInput;
    ko.bindingHandlers.cctvFolderInput = cctvFolderInput;
    ko.bindingHandlers.fileSize = fileSize;
    ko.bindingHandlers.listboxKeyPress = listboxKeyPress;
    ko.bindingHandlers.localDate = localDate;
    ko.bindingHandlers.preventClickEvent = preventClickEvent;

    ko.bindingHandlers.requiredBySummary = requiredBySummary;
    ko.bindingHandlers.resplaceholder = resplaceholder;
    ko.bindingHandlers.restext = restext;
    ko.bindingHandlers.resTitle = resTitle;
    ko.bindingHandlers.resAriaLabel = resAriaLabel;
    ko.bindingHandlers.ariaInvalid = ariaInvalid;
    ko.bindingHandlers.showModal = showModal;
    ko.bindingHandlers.showOverlay = showOverlay;

    ko.bindingHandlers.stickyElement = stickyElementBinding;
    ko.bindingHandlers.textareaAutoResize = textareaAutoResize;
    ko.bindingHandlers.uploadDropZone = uploadDropZone;
    ko.bindingHandlers.uploadEngine = uploadEngine;

    ko.bindingHandlers.restooltip = restooltip;
    ko.bindingHandlers.tooltip = tooltip;

    ko.bindingHandlers.dynamicForm = dynamicForm;
    ko.bindingHandlers.triggerClick = triggerClick;
  }

  static registerMapsBindings() {
    ko.bindingHandlers.map = map;
    ko.bindingHandlers.mapDraggableMarker = mapDraggableMarker;
    ko.bindingHandlers.mapMarkers = mapMarkers;
    ko.bindingHandlers.mapPopup = mapPopup;
  }
}

export default BindingRegistration;
