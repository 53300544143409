import ko, { Observable } from 'knockout';

/**
 * Binding to trigger 'click' event on html element
 * Ko dependency (observable/computed) which binding applied to must be of 'true' value to fire the event
 * @example
 * <button data-bind="triggerClick: fireFocusOnElement">ButtonLabel</button>
 * @param {HTMLElement} element HTMLElement
 * @param {Observable} valueAccessor field to watch
 */
export const triggerClick = {
  update: (element: HTMLElement, valueAccessor: () => Observable): void => {
    if (!element.click || typeof element.click !== 'function') {
      return;
    }
    const option = ko.unwrap(valueAccessor());
    if (option) {
      element.click();
    }
  }
};
