import ko from 'knockout';
import resourceHelper from '../Utils/resourceHelper';

/**
 * Resource placeholder binding to display translated placeholder text on an input field based on
 * content in the resource files.
 */
export const resplaceholder = {

  init: function (element, valueAccessor) {
    var value = valueAccessor();
    if (value) {
      var text = resourceHelper.getString(value);
      ko.applyBindingsToNode(element, { attr: { placeholder: text } });
    }
  }
};