import ko from 'knockout';
import fileSizeFormatter from '../Utils/fileSizeFormatter';


/**
 * Binding to format file size in bytes to KB, MB and GB for display
 * @example
 * <span data-bind="fileSize: 1024"></span>
 */
export const fileSize = {
  update: function (element, valueAccessor, allBindingsAccessor, viewModel, context) {

    var text = fileSizeFormatter.getFormattedFileSize(ko.utils.unwrapObservable(valueAccessor()));

    ko.bindingHandlers.text.update(
      element,
      function () {
        return text;
      },
      allBindingsAccessor,
      viewModel,
      context);
  }
};
