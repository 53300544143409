import ko from 'knockout';
import bindingHelper from './helpers/bindingHelper';
import resourceHelper from '../Utils/resourceHelper';
import logger from '../Utils/logger';

// ReSharper disable FunctionsUsedBeforeDeclared

/**
 * Binding to display localised date using Moment.js formats.
 * It also allows the user to provide a resource name that corresponds to a string which contains placeholders to be replaced
 * by the given values. If any of the values is a date it will be localised using Moment.js formats.
 *
 * @examples
 *  Formats the given date using the default display format "llll":
 *   <div data-bind="localDate: theDate"></div>
 *
 *  Formats the given date using the given display format 'LLL':
 *   <div data-bind="localDate: theDate, displayFormat: 'LLL'"></div>
 *
 *  Finds the resource and replaces the date using the default format "llll":
 *   <div data-bind="localDate: { key: 'ResourceName', params: { date: theDate, username: username } }"></div>
 *
 *  Finds the resource and replaces the date using the given display format 'LL':
 *   <div data-bind="localDate: { key: 'ResourceName', displayFormat: 'LL', params: { date: theDate, username: username } }"></div>
 *
 *   Moment.js formats: http://momentjs.com/docs/#/displaying/
 */

/**
 * Month name, day of month, day of week, year, time (Thursday, September 4 1986 8:30 PM)
 */
var defaultDisplayFormat = 'llll';

export const localDate = {
  update: function (element, valueAccessor, allBindingsAccessor, viewModel, context) {

    var text = resolve(valueAccessor, allBindingsAccessor);

    ko.bindingHandlers.text.update(
      element,
      function () {
        return text;
      },
      allBindingsAccessor,
      viewModel,
      context);
  }
};

/**
 * Binding called with value syntax: data-bind="localDate: theDate"
 * @param {*} valueAccessor
 * @param {*} allBindingsAccessor
 * @return {string}
 */
function resolve(valueAccessor, allBindingsAccessor) {

  var value = ko.utils.unwrapObservable(valueAccessor());
  if (typeof (value) === 'undefined' || value === null) {
    return resourceHelper.getString('NotApplicable');
  }

  var isStringOrDate = isString(value) || bindingHelper.isJsDate(value) || value._isAMomentObject;

  if (isStringOrDate) {
    var displayFormat = allBindingsAccessor.get('displayFormat') || defaultDisplayFormat;
    return resolveDate(value, displayFormat);
  }

  return resolveResourceWithObject(value);
}

/**
 * Binding called with object syntax: data-bind="localDate: { key: 'ResourceName', params: { date: theDate, username: username } }"
 * @param {object} object
 * @return {string}
 */
function resolveResourceWithObject(object) {

  var resourceKey = object.key;
  var params = object.params;

  var displayFormat = object.displayFormat || defaultDisplayFormat;

  if (resourceKey && params) {
    return getLocalizedString(resourceKey, params, displayFormat);
  }

  const message = 'The required properties \'key\' and \'params\' have not been used with the binding \'localDate\'' +
  'when using the object syntax';

  logger.debug(message);

  return '[!' + resourceKey + '!]';
}

/**
 * Get moment date from value and log error if value can't be parsed as a date
 * @param {*} value
 * @param {*} displayFormat
 * @return {string}
 */
function resolveDate(value, displayFormat) {

  var date = bindingHelper.getMomentDate(value);

  if (date.isValid()) {
    return date.format(displayFormat);
  }

  logger.debug('The value \'' + value + '\' cannot be parsed as a date');

  return '[!' + value + '!]';
}

/**
 * Iterate through each params property and replace placeholders in resource text with params values.
 * If a param value is a date then localize before replacing.
 * @param {string} resourceName
 * @param {object} params
 * @param {string} displayFormat
 * @return {string}
 */
function getLocalizedString(resourceName, params, displayFormat) {

  var templateString;
  var replaceKey;

  templateString = resourceHelper.getString(resourceName);

  for (replaceKey in params) {

    if (!Object.prototype.hasOwnProperty.call(params, replaceKey)) {
      continue;
    }

    var replacement = params[replaceKey];

    if (typeof replacement === 'function') {
      replacement = ko.utils.unwrapObservable(replacement());
    }

    var localizedDate = bindingHelper.getMomentDate(replacement);

    if (localizedDate.isValid()) {
      replacement = localizedDate.format(displayFormat);
    }

    templateString = templateString.replace('{' + replaceKey + '}', replacement);
  }

  return templateString;
}

function isString(value) {
  return typeof value === 'string' || value instanceof String;
}
